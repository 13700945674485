import { default as adminsdb6AHeciXUMeta } from "/tmp/build_449d0a04/pages/administration/admins.vue?macro=true";
import { default as _91id_93y5ZL5x5z0jMeta } from "/tmp/build_449d0a04/pages/administration/billing/[id].vue?macro=true";
import { default as indexgyW7fyBt2PMeta } from "/tmp/build_449d0a04/pages/administration/billing/index.vue?macro=true";
import { default as bookingsQOGIun5gWZMeta } from "/tmp/build_449d0a04/pages/administration/bookings.vue?macro=true";
import { default as ecostatisticstuY2KpRNr8Meta } from "/tmp/build_449d0a04/pages/administration/ecostatistics.vue?macro=true";
import { default as exportsi2zh5KGZQzMeta } from "/tmp/build_449d0a04/pages/administration/exports.vue?macro=true";
import { default as fidelityWrR5HbCqUDMeta } from "/tmp/build_449d0a04/pages/administration/fidelity.vue?macro=true";
import { default as invoicesQefTpVj7StMeta } from "/tmp/build_449d0a04/pages/administration/invoices.vue?macro=true";
import { default as policyKUHhMj12JDMeta } from "/tmp/build_449d0a04/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93Z3CvZ1DFlYMeta } from "/tmp/build_449d0a04/pages/administration/payment/[id].vue?macro=true";
import { default as indexXvVTvLINGWMeta } from "/tmp/build_449d0a04/pages/administration/payment/index.vue?macro=true";
import { default as statisticsf47wcSq7UzMeta } from "/tmp/build_449d0a04/pages/administration/statistics.vue?macro=true";
import { default as index1fVp7pnDmAMeta } from "/tmp/build_449d0a04/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitationDTqb3z3A1QMeta } from "/tmp/build_449d0a04/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as indexsRjCYoK9BfMeta } from "/tmp/build_449d0a04/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modify5sy0uxnDHEMeta } from "/tmp/build_449d0a04/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsbnP4UP7VXGMeta } from "/tmp/build_449d0a04/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93pKl06M3nXVMeta } from "/tmp/build_449d0a04/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexrxLDePMgj6Meta } from "/tmp/build_449d0a04/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsUhlr7l8IBQMeta } from "/tmp/build_449d0a04/pages/administration/trip-labels.vue?macro=true";
import { default as administrationnBZcqMeQBOMeta } from "/tmp/build_449d0a04/pages/administration.vue?macro=true";
import { default as swileEkAh5lKaZGMeta } from "/tmp/build_449d0a04/pages/auth/swile.vue?macro=true";
import { default as indexWp49QtS8htMeta } from "/tmp/build_449d0a04/pages/bookings/index.vue?macro=true";
import { default as indexZZjHMbvpzzMeta } from "/tmp/build_449d0a04/pages/bookings/validator/index.vue?macro=true";
import { default as pendingybqSvgbj7eMeta } from "/tmp/build_449d0a04/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_93GlazLUvj6uMeta } from "/tmp/build_449d0a04/pages/car/[id].vue?macro=true";
import { default as fidelityJCn2emRaNrMeta } from "/tmp/build_449d0a04/pages/fidelity.vue?macro=true";
import { default as exchangeI4c6E6kVceMeta } from "/tmp/build_449d0a04/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresEPUOBYOHv0Meta } from "/tmp/build_449d0a04/pages/flight/[id]/fares.vue?macro=true";
import { default as indexLDZjkF1BjaMeta } from "/tmp/build_449d0a04/pages/flight/[id]/index.vue?macro=true";
import { default as forgotZMZuCRdo07Meta } from "/tmp/build_449d0a04/pages/forgot.vue?macro=true";
import { default as get_45swile_45app6BCRIHnPCjMeta } from "/tmp/build_449d0a04/pages/get-swile-app.vue?macro=true";
import { default as helpR34LS07PamMeta } from "/tmp/build_449d0a04/pages/help.vue?macro=true";
import { default as _91id_93YmCQ5JDi77Meta } from "/tmp/build_449d0a04/pages/hotel/[id].vue?macro=true";
import { default as _91id_93TH2TV53lOCMeta } from "/tmp/build_449d0a04/pages/impersonate/[id].vue?macro=true";
import { default as indexcSVlY5kBAfMeta } from "/tmp/build_449d0a04/pages/index.vue?macro=true";
import { default as _91suffix_93JyYMXg6zrMMeta } from "/tmp/build_449d0a04/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_93mfH51ru9prMeta } from "/tmp/build_449d0a04/pages/invite/[token].vue?macro=true";
import { default as _91id_93gEM1X8073XMeta } from "/tmp/build_449d0a04/pages/journeys/[id].vue?macro=true";
import { default as indexGJ7W3IwggYMeta } from "/tmp/build_449d0a04/pages/journeys/index.vue?macro=true";
import { default as membersmEDBiLWhb6Meta } from "/tmp/build_449d0a04/pages/myteam/members.vue?macro=true";
import { default as policy8bvJKowC8iMeta } from "/tmp/build_449d0a04/pages/myteam/policy.vue?macro=true";
import { default as validatorsidwlrTHY7yMeta } from "/tmp/build_449d0a04/pages/myteam/validators.vue?macro=true";
import { default as myteamg8meJZqyQiMeta } from "/tmp/build_449d0a04/pages/myteam.vue?macro=true";
import { default as new_45user9fByHUsle5Meta } from "/tmp/build_449d0a04/pages/new-user.vue?macro=true";
import { default as passwordwJLQKqyU7mMeta } from "/tmp/build_449d0a04/pages/password.vue?macro=true";
import { default as indexrmE8YMwQEYMeta } from "/tmp/build_449d0a04/pages/search/car/[search_id]/index.vue?macro=true";
import { default as indexpL540bMbLNMeta } from "/tmp/build_449d0a04/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93HCmrctzWZbMeta } from "/tmp/build_449d0a04/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93LoIib0JN7NMeta } from "/tmp/build_449d0a04/pages/search/flight/[search_id].vue?macro=true";
import { default as indexZ2ufohDljwMeta } from "/tmp/build_449d0a04/pages/search/flight/index.vue?macro=true";
import { default as index6vRgAlZb8eMeta } from "/tmp/build_449d0a04/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_93V2yQaLBTCDMeta } from "/tmp/build_449d0a04/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexq2K5mpPvLPMeta } from "/tmp/build_449d0a04/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93k88IDS1wtWMeta } from "/tmp/build_449d0a04/pages/search/train/[search_id].vue?macro=true";
import { default as indexVk5S6IVQDvMeta } from "/tmp/build_449d0a04/pages/search/train/index.vue?macro=true";
import { default as search5IVVkDNgoVMeta } from "/tmp/build_449d0a04/pages/search.vue?macro=true";
import { default as indexofUIC7L7EJMeta } from "/tmp/build_449d0a04/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationhFzZ5yiVWxMeta } from "/tmp/build_449d0a04/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyXSn9ITGZBWMeta } from "/tmp/build_449d0a04/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsCrhXowUzYXMeta } from "/tmp/build_449d0a04/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93z9p5fLuaHwMeta } from "/tmp/build_449d0a04/pages/teams/[teamId].vue?macro=true";
import { default as indexH2V94T1G0PMeta } from "/tmp/build_449d0a04/pages/teams/index.vue?macro=true";
import { default as teams7o6NXNUCfhMeta } from "/tmp/build_449d0a04/pages/teams.vue?macro=true";
import { default as indexMDDWNlHs1KMeta } from "/tmp/build_449d0a04/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexbRZsvZ7OQoMeta } from "/tmp/build_449d0a04/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexywcWUmIK6OMeta } from "/tmp/build_449d0a04/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexrUzaCXCaGRMeta } from "/tmp/build_449d0a04/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexhY8M0yZMvxMeta } from "/tmp/build_449d0a04/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licensefsR8bH75WnMeta } from "/tmp/build_449d0a04/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelity73iM1HJNdsMeta } from "/tmp/build_449d0a04/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexlPIKqayI5CMeta } from "/tmp/build_449d0a04/pages/users/[id]/index.vue?macro=true";
import { default as passport4vooXEy1y8Meta } from "/tmp/build_449d0a04/pages/users/[id]/passport.vue?macro=true";
import { default as passwordzbnmAljW0AMeta } from "/tmp/build_449d0a04/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93nvbtFJD66jMeta } from "/tmp/build_449d0a04/pages/users/[id].vue?macro=true";
import { default as indexXZJR1p4MIbMeta } from "/tmp/build_449d0a04/pages/users/index.vue?macro=true";
import { default as voucherszZkQjpkk61Meta } from "/tmp/build_449d0a04/pages/vouchers.vue?macro=true";
import { default as component_45stubq0zcH6EeKQMeta } from "/tmp/build_449d0a04/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubq0zcH6EeKQ } from "/tmp/build_449d0a04/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationnBZcqMeQBOMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_449d0a04/pages/administration/admins.vue").then(m => m.default || m)
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_449d0a04/pages/administration/billing/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_449d0a04/pages/administration/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_449d0a04/pages/administration/bookings.vue").then(m => m.default || m)
  },
  {
    name: "administration-ecostatistics",
    path: "ecostatistics",
    component: () => import("/tmp/build_449d0a04/pages/administration/ecostatistics.vue").then(m => m.default || m)
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_449d0a04/pages/administration/exports.vue").then(m => m.default || m)
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_449d0a04/pages/administration/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_449d0a04/pages/administration/invoices.vue").then(m => m.default || m)
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_449d0a04/pages/administration/organization/policy.vue").then(m => m.default || m)
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_449d0a04/pages/administration/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_449d0a04/pages/administration/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_449d0a04/pages/administration/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93pKl06M3nXVMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_449d0a04/pages/administration/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: index1fVp7pnDmAMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/administration/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_449d0a04/pages/administration/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_449d0a04/pages/administration/teams/[teamId]/policy/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modify5sy0uxnDHEMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/administration/teams/[teamId]/policy/modify.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_449d0a04/pages/administration/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_449d0a04/pages/administration/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_449d0a04/pages/administration/trip-labels.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swileEkAh5lKaZGMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/auth/swile.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_449d0a04/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexZZjHMbvpzzMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/bookings/validator/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingybqSvgbj7eMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/bookings/validator/pending.vue").then(m => m.default || m)
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_449d0a04/pages/car/[id].vue").then(m => m.default || m)
  },
  {
    name: "fidelity",
    path: "/fidelity",
    component: () => import("/tmp/build_449d0a04/pages/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_449d0a04/pages/flight/[id]/exchange.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_449d0a04/pages/flight/[id]/fares.vue").then(m => m.default || m)
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_449d0a04/pages/flight/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotZMZuCRdo07Meta || {},
    component: () => import("/tmp/build_449d0a04/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45app6BCRIHnPCjMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/get-swile-app.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_449d0a04/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_449d0a04/pages/hotel/[id].vue").then(m => m.default || m)
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_449d0a04/pages/impersonate/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexcSVlY5kBAfMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93JyYMXg6zrMMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/invitation/[suffix].vue").then(m => m.default || m)
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_93mfH51ru9prMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_449d0a04/pages/journeys/[id].vue").then(m => m.default || m)
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_449d0a04/pages/journeys/index.vue").then(m => m.default || m)
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_449d0a04/pages/myteam.vue").then(m => m.default || m),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_449d0a04/pages/myteam/members.vue").then(m => m.default || m)
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_449d0a04/pages/myteam/policy.vue").then(m => m.default || m)
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_449d0a04/pages/myteam/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45user9fByHUsle5Meta || {},
    component: () => import("/tmp/build_449d0a04/pages/new-user.vue").then(m => m.default || m)
  },
  {
    name: "password",
    path: "/password",
    meta: passwordwJLQKqyU7mMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_449d0a04/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_449d0a04/pages/search/car/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_449d0a04/pages/search/car/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_449d0a04/pages/search/car/init/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_449d0a04/pages/search/flight/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_449d0a04/pages/search/flight/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_449d0a04/pages/search/hotel/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_449d0a04/pages/search/hotel/[search_id]/rooms/[hotelIds].vue").then(m => m.default || m)
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_449d0a04/pages/search/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_449d0a04/pages/search/train/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_449d0a04/pages/search/train/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teams7o6NXNUCfhMeta?.name,
    path: "/teams",
    meta: teams7o6NXNUCfhMeta || {},
    component: () => import("/tmp/build_449d0a04/pages/teams.vue").then(m => m.default || m),
    children: [
  {
    name: _91teamId_93z9p5fLuaHwMeta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_449d0a04/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_449d0a04/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_449d0a04/pages/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_449d0a04/pages/teams/[teamId]/policy.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_449d0a04/pages/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_449d0a04/pages/teams/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_449d0a04/pages/train/[id]/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_449d0a04/pages/train/[id]/exchange/changeable-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_449d0a04/pages/train/[id]/exchange/section-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_449d0a04/pages/train/[id]/exchange/train-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_449d0a04/pages/train/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nvbtFJD66jMeta?.name,
    path: "/users/:id()",
    component: () => import("/tmp/build_449d0a04/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_449d0a04/pages/users/[id]/driver-license.vue").then(m => m.default || m)
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_449d0a04/pages/users/[id]/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_449d0a04/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_449d0a04/pages/users/[id]/passport.vue").then(m => m.default || m)
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_449d0a04/pages/users/[id]/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_449d0a04/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_449d0a04/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_449d0a04/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: component_45stubq0zcH6EeKQMeta?.name,
    path: "/metrics",
    component: component_45stubq0zcH6EeKQ
  },
  {
    name: component_45stubq0zcH6EeKQMeta?.name,
    path: "/en",
    component: component_45stubq0zcH6EeKQ
  },
  {
    name: component_45stubq0zcH6EeKQMeta?.name,
    path: "/fr",
    component: component_45stubq0zcH6EeKQ
  },
  {
    name: component_45stubq0zcH6EeKQMeta?.name,
    path: "/pt-BR",
    component: component_45stubq0zcH6EeKQ
  },
  {
    name: component_45stubq0zcH6EeKQMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubq0zcH6EeKQ
  },
  {
    name: component_45stubq0zcH6EeKQMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubq0zcH6EeKQ
  },
  {
    name: component_45stubq0zcH6EeKQMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubq0zcH6EeKQ
  }
]